import {generateTQL} from "./tql_utils";
import {TREE_CATEGORIES} from "./constants";
import {getFlatData} from "./data_utils";
import {
    getKeysOfNodeAndChildren,
    getNodeFromKey,
    getNodesFromKeys,
    getParentNode,
    getSimplifiedKeysFromCodes
} from "./key_utils";


export function handleCheck(event, Key: string, checkedKeysWithData, indeterminateKeys, setCheckedKeysWithData, setIndeterminateKeys) {

    const checkedKeys = checkedKeysWithData.map(el => el['Key'])
    const currentVocabulary = Key.split("$")[0]

    const delta = event === null || event.target.checked || (event.type === "click" && !checkedKeys.includes(Key))

    const node = getNodeFromKey(Key)

    const childKeys = getFlatData(currentVocabulary).filter(el => el['Key'].startsWith(Key)).map(el => el['Key'])

    let localCheckedKeys;
    if (delta) {
        localCheckedKeys = [...new Set([...checkedKeys, ...childKeys])]
    } else {
        localCheckedKeys = [...new Set(checkedKeys.filter(key => !childKeys.includes(key)))]
    }

    const finalSelectedKeys = []
    const finalIndetermineeKeys = []

    TREE_CATEGORIES
        .forEach(vocab => {
            const categoryCheckedKeys = localCheckedKeys.filter(key => key.startsWith(vocab))
            if (categoryCheckedKeys.length === 0) return
            const localIndeterminateKeys = indeterminateKeys
            let newSelectedKeys, newIndeterminateKeys

            if (vocab === currentVocabulary) {
                ({
                    newSelectedKeys,
                    newIndeterminateKeys
                } = readjustParentKeys(node, categoryCheckedKeys, localIndeterminateKeys));
            } else {
                newSelectedKeys = categoryCheckedKeys
                newIndeterminateKeys = []
            }

            finalSelectedKeys.push(...newSelectedKeys)
            finalIndetermineeKeys.push(...newIndeterminateKeys)
        })

    // setCheckedKeys(finalSelectedKeys)
    const newcheckedKeysWithData = getNodesFromKeys(finalSelectedKeys)
    const newCheckedKeysWithDataUnique = [...new Set(newcheckedKeysWithData)]
    setCheckedKeysWithData(newCheckedKeysWithDataUnique)
    // fetch("http://127.0.0.1:5000/get_closest_phenotype", {
    //     method: "POST",
    //     headers: {
    //         "Content-Type": "application/json"
    //     },
    //     body: JSON.stringify({keys: finalSelectedKeys})
    // }).then(data => data.json()).then(data => console.log(data))
    setIndeterminateKeys(finalIndetermineeKeys)

    return [newCheckedKeysWithDataUnique, finalIndetermineeKeys]
}


export function readjustParentKeys(node, selectedKeys, indeterminateKeys = []) {
    let newSelectedKeys = selectedKeys
    let newIndeterminateKeys = indeterminateKeys

    const childKeys = getKeysOfNodeAndChildren(node.Key).flat(Infinity).filter(key => key !== node.Key)

    if (childKeys.length === 0) {

    } else if (childKeys.every(key => selectedKeys.includes(key))) {
        newSelectedKeys = [...selectedKeys, node.Key]
        newIndeterminateKeys = indeterminateKeys.filter(key => !newSelectedKeys.includes(key))
    } else if (childKeys.some(key => selectedKeys.includes(key))) {
        newSelectedKeys = selectedKeys.filter(key => key !== node.Key)
        newIndeterminateKeys = [...indeterminateKeys, node.Key]
    } else if (selectedKeys.includes(node['Key'])) {
        newSelectedKeys = [...selectedKeys, ...childKeys]
        newIndeterminateKeys = indeterminateKeys.filter(key => key !== node.Key)
    } else if (!childKeys.some(key => selectedKeys.includes(key))) {
        newSelectedKeys = selectedKeys.filter(key => ![...childKeys, node.Key].includes(key))
        newIndeterminateKeys = indeterminateKeys.filter(key => key !== node.Key)
    } else {
        newSelectedKeys = selectedKeys.filter(key => !childKeys.includes(key))
        // newIndeterminateKeys = indeterminateKeys.filter(key => !childKeys.includes(key))
        newIndeterminateKeys = [...indeterminateKeys, node.Key]
    }

    newIndeterminateKeys = newIndeterminateKeys.filter(key => !newSelectedKeys.includes(key))

    if (node.Key.split("$").length !== 1) {
        const parentNode = getParentNode(node.Key)
        return readjustParentKeys(parentNode, newSelectedKeys, newIndeterminateKeys)
    } else {
        return {newSelectedKeys, newIndeterminateKeys}
    }
}



export function handleMultipleChecks(newCheckedKeys, checkedKeysWithData, indeterminateKeys, setCheckedKeysWithData, setIndeterminateKeys) {
    let newCheckedKeysWithData = checkedKeysWithData
    let newIndeterminateKeys = indeterminateKeys

    newCheckedKeys.forEach(key => {
        [newCheckedKeysWithData, newIndeterminateKeys] = handleCheck(null, key, newCheckedKeysWithData, newIndeterminateKeys, setCheckedKeysWithData, setIndeterminateKeys)

    })


    setCheckedKeysWithData(newCheckedKeysWithData)
    setIndeterminateKeys(newIndeterminateKeys)
}


export async function organizeText(code) {
    const vocabularies = ['ICD10', 'ICD9', "CPT", "RX", "ATC"]

    if (code === "") {
        return

    }
    let tmpCode = code.replaceAll(" ", "")
    const regex = /(((ICD10|ICD9|CPT|RX|ATC)="([\w.]+)")|((ICD10|ICD9|CPT|RX|ATC)\(([\w".,\s]+)\)))/gmi
    let m;

    let values = {}

    vocabularies.forEach(vocab => {
        values[vocab] = []
    })


    while ((m = regex.exec(tmpCode)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }

        if (typeof m[3] === "undefined" || m[3] === null) {
            const vocab = m[6]

            const codesToParse = m[7]

            const subCodesRegex = /"(.+?)"/gm
            let codes = codesToParse.match(subCodesRegex).map(item => item.replaceAll('"', ""))

            codes.forEach(code => {
                values[vocab].push(code)

            })
        } else {
            const vocab = m[3]
            const term = m[4]
            values[vocab].push(term)
        }
    }

    const tqls = vocabularies.map(async vocab => {

        const simplifiedKeys = getSimplifiedKeysFromCodes(vocab, values[vocab])
        return await generateTQL(vocab, simplifiedKeys, true, false)

    })

    return await Promise.all(tqls)
        .then(awaitedTQLs => "UNION(" + awaitedTQLs.filter(el => el !== "").join(", ") + ")")
        .catch(e => console.log(e))

}


export async function getFile(filename) {
    return await fetch(filename).then(data => data.json())
}


