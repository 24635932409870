import {getFile} from "./util";
import {Bounce, toast} from 'react-toastify';
import {getAllMap} from "./data_utils";
import putDataToCloudwatch from "./cloudwatch_utils";


export function getLeafTermsATC(checkedKeys) {
    return checkedKeys.map(key => key.split("$").pop()).filter(key => key.length === 7)
}


export function getHierarchicalKeysATC(checkedKeys) {
    checkedKeys = [...new Set(checkedKeys)].sort()
    let hierarchyTerms = []

    const removeSubcodes = (checkedKeysTmp) => {
        if (checkedKeysTmp.length === 0) {
            return hierarchyTerms
        }

        let firstEl = checkedKeysTmp.shift()

        hierarchyTerms = [...hierarchyTerms, firstEl]
        checkedKeysTmp = checkedKeysTmp.filter((value) => !(value.startsWith(firstEl)))

        if (checkedKeysTmp.length !== 0) {
            removeSubcodes(checkedKeysTmp)
        }

    }

    removeSubcodes([...checkedKeys])
    return hierarchyTerms
}

export function getHierarchicalKeysICD10(checkedKeys) {
    checkedKeys = [...new Set(checkedKeys.sort())].sort()
    let hierarchyTerms = []

    const removeSubcodes = (checkedKeysTmp) => {
        if (checkedKeysTmp.length === 0) {
            return hierarchyTerms
        }

        let firstEl = checkedKeysTmp.shift()
        if (firstEl.match(/\$/g).length < 3) {
            removeSubcodes(checkedKeysTmp)
        } else {
            hierarchyTerms = [...hierarchyTerms, firstEl]
            checkedKeysTmp = checkedKeysTmp.filter((value) => !(value.startsWith(firstEl)))

            if (checkedKeysTmp.length !== 0) {
                removeSubcodes(checkedKeysTmp)
            }
        }
    }

    removeSubcodes([...checkedKeys])
    return hierarchyTerms
}

export function getHierarchicalKeysICD9(checkedKeys) {
    checkedKeys = [...new Set(checkedKeys.sort())].sort()
    let hierarchyTerms = []

    const removeSubcodes = (checkedKeysTmp) => {
        if (checkedKeysTmp.length === 0) {
            return hierarchyTerms
        }

        let firstEl = checkedKeysTmp.shift()
        if (firstEl.split("$").pop().includes("-")) {
            removeSubcodes(checkedKeysTmp)
        } else {
            hierarchyTerms = [...hierarchyTerms, firstEl]
            checkedKeysTmp = checkedKeysTmp.filter((value) => !(value.startsWith(firstEl)))

            if (checkedKeysTmp.length !== 0) {
                removeSubcodes(checkedKeysTmp)
            }
        }
    }

    removeSubcodes([...checkedKeys])
    return hierarchyTerms
}

export function getICD10LeafTerms(checkedKeys) {
    const re = RegExp('\\$\\w\\w\\w\\.\\w*$')
    let leaves = checkedKeys.filter((value) => {
        return re.test(value)
    }).flat(Infinity).map(value => {
        return value.split("$").pop()
    }).sort()
    return (leaves)
}

export function getCPTLeafTerms(checkedKeys) {
    const re = RegExp('\\$\\w\\w\\w\\w\\w$')
    let leaves = checkedKeys.filter((value) => {
        return re.test(value)
    }).flat(Infinity).map(value => {
        return value.split("$").pop()
    }).sort()
    return (leaves)
}

export async function mapATCToRx(ATCTerms) {
    let atc_rxnorm_map = await getFile("atc_rxnorm.json")
    const foundTerms = atc_rxnorm_map.filter(el => ATCTerms.includes(el['ATC'])).map(el => el['ATC'])

    const notFound = ATCTerms.filter(atc => !foundTerms.includes(atc))

    const synonyms = await getFile("atc_synonyms.json")
    const notFoundTerms = synonyms.filter(synonym => notFound.includes(synonym['ATCCode']))

    if (notFoundTerms.length) {
        console.log("No map found for the following: " + notFoundTerms.map(term => term['ATCCode'] + ": " + term['ATCDescription']).join("\n"))
    }
    return atc_rxnorm_map.filter(el => ATCTerms.includes(el['ATC'])).map(el => el['RXNORM'])
}

export function extractRelevantCodes(vocabulary, keys) {
    switch (vocabulary) {
        case "ICD10":
            return getHierarchicalKeysICD10(keys).map(key => key.split("$").pop())
        case "ICD9":
            return getHierarchicalKeysICD9(keys).map(key => key.split("$").pop())
        case "CPT":
            return getCPTLeafTerms(keys).map(key => key.split("$").pop())
        case "ATC":
            return getHierarchicalKeysATC(keys).map(key => key.split("$").pop())
        // return getLeafTermsATC(keys)
        case "RxNorm":
        case "RX":
            return keys
        default:
            throw new Error()
    }
}


export function getExpandedKeysForKey(Key) {
    let keys = Key.split("$")
    let expandedKeys = []
    // Get hashma
    const el = getAllMap().get(Key)
    const hasChildren = el['children'].length !== 0
    // const hasChildren = getAllMap().get(el[(el => el['Key'] === Key)[0]['Children'] !== undefined

    for (let i = 0; i < keys.length; i++) {
        expandedKeys.push(keys.slice(0, i + 1).join("$"))
    }

    if (!hasChildren) {
        expandedKeys.pop()
    }

    return expandedKeys

}

export function copyCodes(codes) {
    const codesStr = codes.map(codeList => {
        const vocab = codeList[0]
        return `${vocab}\n${codeList[1].map(el => {
            return el['Code'] + ": " + el['Description']
        })
            .join("\n")}`
    }).join("\n\n")

    const keys = codes.map(codeList => {
        return codeList[1].map(el => {
            return el['Key']
        })
    }).flat()

    navigator.clipboard.writeText(codesStr).then(() => {
        // console.log("Codes copied to clipboard", keys)
        putDataToCloudwatch('copyCodes', keys)
    }).catch(e => console.log(e))


    toast.success('Codes Copied!', {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
}
