import {getAllMap, getFlatData, getTreeData} from "./data_utils";
import {readjustParentKeys} from "./util";

export function getKeysOfNodeAndChildren(key) {
    const node = getNodeFromKey(key)
    const children = typeof node.children === "undefined" ? [] : node.children
    // Get child Keys recurisvely
    const childKeys = children.map(child => getKeysOfNodeAndChildren(child)).flat(Infinity)
    return [node.Key, ...childKeys]
}

export function getNodeFromKey(key: string) {
    const node = getAllMap().get(key)

    if (node === undefined) {
        throw new Error("Node not found")
    }

    return node
}

export function getNodesFromKeys(keys: []) {
    return keys.map(key => getNodeFromKey(key))
}

export function getParentNode(key) {
    const parentKey = key.split("$").slice(0, -1).join("$");
    return getNodeFromKey(parentKey)
}

export function getKeysFromCodes(vocabulary, codes) {
    let flatData;
    switch (vocabulary) {
        case "ICD10":
        case "ICD9":
        case "CPT":
        case "ATC":
            flatData = getFlatData(vocabulary)
            break;
        case "RxNorm":
        case "RX":
        case "LOINC":
            return codes
        default:
            throw new Error()
    }

    const selectedKeys = flatData.filter(el => codes.includes(el['Code']))
        .map(el => el['Key'])
    return [...new Set(selectedKeys)]
}

export function getSimplifiedKeysFromCodes(vocabulary, codes) {
    codes = [...new Set(codes)]

    if (vocabulary === "RX" || vocabulary === "RxNorm" || vocabulary === "ATC") {
        return codes
    }
    const keys_all = getKeysFromCodes(vocabulary, codes);


    const tree = getTreeData(vocabulary)

    const nodes = getNodesFromKeys(keys_all)
    if (codes.length !== nodes.length) {
        throw new Error()
    }

    let checkedKeys = keys_all, indeterminateKeys = []
    nodes.forEach(node => {
        let {
            newSelectedKeys,
            newIndeterminateKeys
        } = readjustParentKeys(tree, node, checkedKeys, indeterminateKeys)
        checkedKeys = newSelectedKeys
        indeterminateKeys = newIndeterminateKeys
    })

    checkedKeys = [...new Set(checkedKeys)]
    indeterminateKeys = [...new Set(indeterminateKeys)]

    return checkedKeys
}