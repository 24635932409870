// services/apiSlice.js
import {createApi} from '@reduxjs/toolkit/query/react';
import {ungzip} from 'pako'; // You may need to install pako for gzip decompression

export const apiService = createApi({
    reducerPath: 'api',
    baseQuery: async ({url, method, body, headers}) => {
        const request = new Request(url, {method, body, headers});
        const cache = await caches.open('api-cache');

        const cachedResponse = await cache.match(request);
        if (cachedResponse) {
            console.log("Data is already cached")
            const arrayBuffer = await cachedResponse.arrayBuffer();
            const decompressed = ungzip(new Uint8Array(arrayBuffer));
            const text = new TextDecoder().decode(decompressed);
            return {data: JSON.parse(text)};
        }

        const response = await fetch(request);
        if (response.ok) {
            const clone = response.clone();
            cache.put(request, clone);
        }

        const arrayBuffer = await response.arrayBuffer();
        const decompressed = ungzip(new Uint8Array(arrayBuffer));
        const text = new TextDecoder().decode(decompressed);
        return {data: JSON.parse(text)};
    },
    endpoints: (builder) => ({
        getAlldata: builder.query({
            query: () => ({
                url: `alldata.json.gz`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),
    }),
});

export const {useGetAlldataQuery} = apiService;
