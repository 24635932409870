import React, {useEffect, useState} from 'react';

import LeftDrawerComponent from "./components/Root/LeftDrawerComponent";

import "./App.css";
import 'react-toastify/dist/ReactToastify.css';

import {ToastContainer} from 'react-toastify';
import {useGetAlldataQuery} from "./redux/api";
import {useDispatch} from 'react-redux';
import {Box, Button, ButtonGroup, Grid, Typography} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// import {CustomTextField} from "./CustomTextField";

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { getCurrentUser } from 'aws-amplify/auth';
import { CognitoIdentityProviderClient, ListUsersCommand } from "@aws-sdk/client-cognito-identity-provider"; // ES Modules import


import {
    setAllFlatData,
    setAllMapData,
    setAtcFlatData,
    setAtcTreeData,
    setAtcTreeMap,
    setCptFlatData,
    setCptTreeData,
    setCptTreeMap,
    setIcd10FlatData,
    setIcd10TreeData,
    setIcd10TreeMap,
    setIcd9FlatData,
    setIcd9TreeData,
    setIcd9TreeMap,
    setRxNormFlatData
} from "./redux/slices";
import TreeVisualizationComponent from "./components/TreeVisualizationComponent";
import {FLAG_SETTINGS, TREE_CATEGORIES} from "./utils/constants";
import {RxNormList} from "./components/RXNorm/RxNormList";
import SearchComponent from "./components/Search/SearchComponent";
import {createTree} from "./utils/data_utils";

import {Amplify} from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';



import { withAuthenticator } from '@aws-amplify/ui-react';
import { Authenticator } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css';
import config from './amplifyconfiguration.json';
import { uploadData } from 'aws-amplify/storage';

Amplify.configure(config);



const App = ({ signOut, user }) => {


    // Keys States
    const [expandedKeys, setExpandedKeys] = useState([]);
    // const [checkedKeys, setCheckedKeys] = useState([]);

    const [checkedKeysWithData, setCheckedKeysWithData] = useState([]);
    const [indeterminateKeys, setIndeterminateKeys] = useState([])
    const [highlightedKeys, setHighlightedKeys] = useState([])

    const [showSearchTermsInContext, setShowSearchTermsInContext] = useState(true);


    // Search States
    const [searchValue, setSearchValue] = useState("")

    useEffect(() => {
        if (searchValue === "") {
            setSearchResults(null)
            setNumSearchResults(null)
        }
    }, [searchValue]);


    const [vocabsToSearch, setVocabsToSearch] = useState({
        CPT: false,
        ICD9: false,

        ICD10: false,
        // RX: false,
        ATC: false,
    });
    const [searchResults, setSearchResults] = useState(null)
    const [numSearchResults, setNumSearchResults] = useState(null)

    const leftDrawerWidth = "15%";

    const {data: allDataRaw, isLoading: isAllDataLoading} = useGetAlldataQuery();

    const [configFlags, setConfigFlags] = useState({
        convertToICD9: false,
        // ingredientsOnly: true,
    });

    // Update the flags state without needing separate set function for each flag
    const updateFlag = (flag, value) => {
        setConfigFlags(prevFlags => ({
            ...prevFlags, [flag]: value
        }));
    };

    const [selectedCategory, setSelectedCategory] = useState('CPT'); // Default selected category
    const isTreeDataVisualization = TREE_CATEGORIES.includes(selectedCategory);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isAllDataLoading && typeof allDataRaw !== 'undefined' && allDataRaw.length > 0) {
            const vocabToDispatch = {
                CPT: {
                    flatDataDispatch: setCptFlatData,
                    treeDataDispatch: setCptTreeData,
                    treeMapDispatch: setCptTreeMap
                },
                ICD10: {
                    flatDataDispatch: setIcd10FlatData,
                    treeDataDispatch: setIcd10TreeData,
                    treeMapDispatch: setIcd10TreeMap
                },
                ICD9: {
                    flatDataDispatch: setIcd9FlatData,
                    treeDataDispatch: setIcd9TreeData,
                    treeMapDispatch: setIcd9TreeMap
                },
                ATC: {
                    flatDataDispatch: setAtcFlatData,
                    treeDataDispatch: setAtcTreeData,
                    treeMapDispatch: setAtcTreeMap
                },

            };

            const allDataWithVocab = allDataRaw
                .map(e => {
                    const arr = e['Key'].split("$")
                    const Vocabulary = arr[0];
                    const Code = arr.pop()
                    const Parent = arr.join("$")
                    return {...e, Vocabulary, Code, Parent}
                }).filter(e => {
                    if (e.Vocabulary === "RX") {
                        return e.RxNormType === "Ingredient"
                    } else {
                        return true
                    }
                })

            // setAllDataWithVocab(allDataWithVocab)

            function convertToMap(node, parentKey = null) {
                const nodeMap = new Map();

                function traverse(currentNode, parentKey) {
                    const {Key, children = [], ...rest} = currentNode;
                    nodeMap.set(Key, {
                        ...rest,
                        Key: Key,
                        Parent: parentKey,
                        children: children.map(child => child.Key)
                    });
                    children.forEach(child => traverse(child, Key));
                }

                traverse(node, parentKey);
                return nodeMap;
            }


            let allMapData = new Map()

            Object.entries(vocabToDispatch).forEach(([vocab, dispatchFunctions]) => {
                const filteredData = allDataWithVocab.filter(e => e.Vocabulary === vocab);
                dispatch(dispatchFunctions.flatDataDispatch(filteredData));

                const tree = createTree(filteredData)[0]
                dispatch(dispatchFunctions.treeDataDispatch(tree));

                const map = convertToMap(tree);
                allMapData = new Map([...allMapData.entries(), ...map.entries()]);

                dispatch(dispatchFunctions.treeMapDispatch(convertToMap(tree)))
            });

            dispatch(setAllFlatData(allDataWithVocab));
            dispatch(setAllMapData(allMapData))

            dispatch(setRxNormFlatData(allDataWithVocab.filter(e => e.Vocabulary === "RX" && e.RxNormType === "Ingredient")));
        }
    }, [dispatch, allDataRaw, isAllDataLoading]);


    function clearAll() {
        // setCheckedKeys([])
        setCheckedKeysWithData([])
        setIndeterminateKeys([])
        setExpandedKeys([])
        setSearchValue("")
        setSelectedCategory('CPT')

        // sets vocabs to Search to all false
        setVocabsToSearch(prevState => {
            return Object.keys(prevState).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, {})
        })

    }

    useEffect(() => {
        // If Selected Category is ICD9, add ICD9$001-999.99 to expandedKeys
        if (selectedCategory === "ICD9") {
            setExpandedKeys([...new Set([...expandedKeys, "ICD9$001-999.99"])]);
        }
    }, [selectedCategory, setExpandedKeys, expandedKeys]);

    // try {
    //     const result =  uploadData({
    //         path: 'public/album/2024/1.jpg',
    //         // Alternatively, path: ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
    //         data: file,
    //         options: {
    //             onProgress // Optional progress callback.
    //         }
    //     }).result;
    //     console.log('Succeeded: ', result);
    // } catch (error) {
    //     console.log('Error : ', error);
    // }

    const fetchData = async () => {
        try {
            // const currentUserInfo = await Auth.currentAuthenticatedUser();
            // const idToken = currentUserInfo.signInUserSession.idToken.jwtToken;
            const session = await fetchAuthSession();
            const accessToken = session.tokens?.idToken?.toString()

            const response = await fetch("https://r1ctld4726.execute-api.us-west-2.amazonaws.com/prod/Auth", {
                headers: {
                    Authorization: accessToken
                }
            });


            console.log(accessToken)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };




    return (<Grid container sx={{height: '100vh'}} flexDirection={"column"}>
            <LeftDrawerComponent
                leftDrawerWidth={leftDrawerWidth}
                expandedKeys={expandedKeys}
                setExpandedKeys={setExpandedKeys}
                checkedKeysWithData={checkedKeysWithData}
                setCheckedKeysWithData={setCheckedKeysWithData}
                indeterminateKeys={indeterminateKeys}
                setIndeterminateKeys={setIndeterminateKeys}
                clearAll={clearAll}
                configFlags={configFlags}
                updateFlag={updateFlag}
                flagSettings={FLAG_SETTINGS}
                signOut={signOut}
            />

            {/* Center Area - Adjusted to not overlap with Drawer */}
            <Box sx={{
                flexGrow: 1,
                display: 'flex',
                width: `calc(100% - ${leftDrawerWidth})`, // marginLeft: `calc(${leftDrawerWidth})`,
            }}
            >

                {/* Visualization or Selected Component Data Section */}
                <Box sx={{width: '70%', overflow: 'auto', pl: 2, m: 4}}>
                    <Box
                    sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2}}
                    >
                    <Typography variant={"h3"}>Tree Visualization</Typography>
                    <FormControlLabel
                        control={<Switch
                            checked={showSearchTermsInContext}
                            onChange={() => setShowSearchTermsInContext(!showSearchTermsInContext)}
                            />}
                        label="Show Results in Context"
                        sx={{marginRight: 0}} // Adjust spacing as needed
                    >
                    </FormControlLabel>
                    </Box>

                    {/* Category Buttons */}
                    <Box sx={{my: 2, display: 'flex', justifyContent: 'center'}}>
                        <ButtonGroup variant="text" aria-label="text button group" sx={{width: '100%'}}>
                            {[...TREE_CATEGORIES].map((category) => (<Button
                                key={category}
                                onClick={() => setSelectedCategory(category)}
                                sx={{
                                    flexGrow: 1,
                                    color: selectedCategory === category ? 'white' : 'grey',
                                    backgroundColor: selectedCategory === category ? '#1976d2' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: selectedCategory === category ? '#115293' : 'rgba(0, 0, 0, 0.04)',
                                    },
                                    transition: 'background-color 0.3s',
                                }}
                            >
                                {category}
                            </Button>))}
                        </ButtonGroup>


                    </Box>
                    {/*<HeaderLabel text={selectedCategory}/>*/}
                    {/* Visualization Component */}
                    {isTreeDataVisualization && (<TreeVisualizationComponent
                        vocabulary={selectedCategory}
                        expandedKeys={expandedKeys}
                        setExpandedKeys={setExpandedKeys}
                        checkedKeysWithData={checkedKeysWithData}
                        setCheckedKeysWithData={setCheckedKeysWithData}
                        showSearchTermsInContext={showSearchTermsInContext}
                        searchResults={searchResults}
                        // checkedKeys={checkedKeys}
                        // setCheckedKeys={setCheckedKeys}
                        indeterminateKeys={indeterminateKeys}
                        setIndeterminateKeys={setIndeterminateKeys}
                        flags={configFlags}
                        highlightedKeys={highlightedKeys}
                    />)}

                    {selectedCategory === "RX" && (
                        <RxNormList/>
                    )}
                </Box>
                <SearchComponent
                    checkedKeysWithData={checkedKeysWithData}
                    setCheckedKeysWithData={setCheckedKeysWithData}
                    expandedKeys={expandedKeys}
                    setExpandedKeys={setExpandedKeys}
                    indeterminateKeys={indeterminateKeys}
                    setIndeterminateKeys={setIndeterminateKeys}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    vocabsToSearch={vocabsToSearch}
                    setVocabsToSearch={setVocabsToSearch}
                    searchResults={searchResults}
                    numSearchResults={numSearchResults}
                    setSearchResults={setSearchResults}
                    setNumSearchResults={setNumSearchResults}
                    setSelectedCategory={setSelectedCategory}
                    setHighlightedKeys={setHighlightedKeys}
                />
            </Box>




            <ToastContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover
                theme="light"
            />
        </Grid>
    );

}


export default App;
