import {createSlice} from '@reduxjs/toolkit';


export const main = createSlice({
    name: 'data',
    initialState: {treeData: null},
    reducers: {
        setIcd9TreeData: (state, action) => {
            state.icd9TreeData = action.payload;
        },
        setIcd9FlatData: (state, action) => {
            state.icd9FlatData = action.payload;
        },
        setAtcTreeData: (state, action) => {
            state.atcTreeData = action.payload;
        },
        setAtcFlatData: (state, action) => {
            state.atcFlatData = action.payload;
        },
        setIcd10TreeData: (state, action) => {
            state.icd10TreeData = action.payload;
        },
        setIcd10FlatData: (state, action) => {
            state.icd10FlatData = action.payload;
        },
        setCptTreeData: (state, action) => {
            state.cptTreeData = action.payload;
        },
        setCptFlatData: (state, action) => {
            state.cptFlatData = action.payload;
        },
        setRxNormFlatData: (state, action) => {
            state.rxNormFlatData = action.payload;
        },
        setRxnormIngredientFlatData: (state, action) => {
            state.rxnormIngredientFlatData = action.payload;
        },
        setAllFlatData: (state, action) => {
            state.allFlatData = action.payload;
        },

        // Tree Maps
        setIcd9TreeMap: (state, action) => {
            state.icd9TreeMap = action.payload;
        },
        setAtcTreeMap: (state, action) => {
            state.atcTreeMap = action.payload;
        },
        setIcd10TreeMap: (state, action) => {
            state.icd10TreeMap = action.payload;
        },
        setCptTreeMap: (state, action) => {
            state.cptTreeMap = action.payload;
        },
        setAllMapData: (state, action) => {
            state.allMapData = action.payload;
        },
    },
});

export const {
    setCptTreeData, setCptFlatData,
    setIcd10TreeData, setIcd10FlatData,
    setIcd9TreeData, setIcd9FlatData,
    setAtcTreeData, setAtcFlatData,
    setRxNormFlatData,
    setRxnormIngredientFlatData,
    setAllFlatData,
    setIcd9TreeMap, setAtcTreeMap, setIcd10TreeMap, setCptTreeMap,
    setAllMapData
} =
    main.actions;

export default main.reducer;


