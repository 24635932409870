import {extractRelevantCodes} from "./code_utils";
import {Bounce, toast} from 'react-toastify';
import {getAllMap, getFlatData} from "./data_utils";
import putDataToCloudwatch from "./cloudwatch_utils";


import { generateClient } from "aws-amplify/api";
import { createPhenotype } from '../graphql/mutations';
import {number} from "prop-types";


export async function generateTQL(nodes, flags) {
    // const keysWithVocabularies = keys.map(key => [key.split("$").shift(), key])

    const keys = nodes.map(node => node.Key)

    const allVocabs = [...new Set(keys.map(key => key.split("$").shift()))]

    let numberOfVocabs = 0
    const tql = allVocabs.map(vocab => {

        const filteredKeys = keys.filter(key => key.split("$").shift() === vocab)
        if (filteredKeys.length === 0) {
            return null
        }

        numberOfVocabs += 1

        let tqlVocab = vocab;

        const codes = extractRelevantCodes(tqlVocab, filteredKeys)
        let codeStr = codes
            .map(code => {
                return "\"" + code + "\""
            }).join(",")

        codeStr = tqlVocab + "(" + codeStr + ")"

        return codeStr

        // if (flags.generateSourceRx && (vocab === "RX")) {
        //     const source_rx_str = getFlatData("RX")
        //         .filter(rxnormItem => filteredKeys.includes(rxnormItem['Key']))
        //         .map(rxnormItem => rxnormItem['Description'])
        //         .map(term => "%" + term.replace(/[\W_]+/g, "%") + "%")
        //         .map(term => 'FIND "' + term + '"')
        //         .join(", ")
        //
        //     codeStr = codeStr + ", SOURCE_RX(" + source_rx_str + ")"
        // }
    }).filter(str => str)
        .join(", ")


    return (numberOfVocabs > 1 ? "UNION(" + tql + ")" : tql)

}


export async function copyTQL(checkedKeys, configFlags) {
    await generateTQL(checkedKeys, configFlags)
        .then(tql => {
            navigator.clipboard.writeText(tql).catch(e => console.log(e))
        }).then((tql) => {
            putDataToCloudwatch("copyTQL", checkedKeys.map(node => node.Key))

            const client = generateClient()
            const keys = checkedKeys.map(node => node.Key)

            const values = []
            const data = getAllMap()

            // getAllMap().then((data) => {
            //
            // })

            client.graphql({
                query: createPhenotype,
                variables: {
                    input: {
                        "Keys": checkedKeys.map(node => node.Key)
                    }
                }
            });
        })

    toast.success('TQL Copied!', {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
}
