import {configureStore} from '@reduxjs/toolkit';
import {apiService} from './api';
import mainReducer from './slices';


export const store = configureStore({
    reducer: {
        // ... your other reducers ...
        [apiService.reducerPath]: apiService.reducer,
        main: mainReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,  // Disable serializability checks
        }).concat(apiService.middleware),
    // Include other middleware as necessary
});

// Include thunks or other Redux middleware in the getDefaultMiddleware call as needed.