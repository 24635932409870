import {store} from "../redux/store";

const _getData = (vocabulary, type) => {
    const state = store.getState();

    if (type === "tree") {
        switch (vocabulary) {
            case "ICD10":
                return state.main.icd10TreeData
            case "ICD9":
                return state.main.icd9TreeData
            case "CPT":
                return state.main.cptTreeData
            case "ATC":
                return state.main.atcTreeData
            case "RX":
            default:
                throw new Error()
        }
    } else if (type === "flat") {
        switch (vocabulary) {
            case "ICD10":
                return state.main.icd10FlatData
            case "ICD9":
                return state.main.icd9FlatData
            case "CPT":
                return state.main.cptFlatData
            case "ATC":
                return state.main.atcFlatData
            case "RX":
                return state.main.rxNormFlatData
            default:
                throw new Error()
        }
    } else if (type === "map") {
        switch (vocabulary) {
            case "ICD10":
                return state.main.icd10TreeMap
            case "ICD9":
                return state.main.icd9TreeMap
            case "CPT":
                return state.main.cptTreeMap
            case "ATC":
                return state.main.atcTreeMap
            case "ALL":
                return state.main.allMapData
            default:
                throw new Error()
        }

    }
};
export const getTreeData = (vocabulary) => {
    return _getData(vocabulary, "tree")
}
export const getFlatData = (vocabulary) => {
    return _getData(vocabulary, "flat")
}
export const getMap = (vocabulary) => {
    return _getData(vocabulary, "map")
}

export const getAllMap = () => {
    return _getData("ALL", "map")

}


export const createTree = dataset => {
    const hashTable = Object.create(null);
    dataset.forEach(aData => {
        hashTable[aData.Key] = {...aData, children: []}
    });
    const dataTree = [];
    dataset
        .forEach(aData => {
            if (aData.Parent) {
                hashTable[aData.Parent].children.push(hashTable[aData.Key])
            } else {
                dataTree.push(hashTable[aData.Key])
            }
        });
    return dataTree;
};