import React, {useEffect, useState} from 'react';
import {Box, Tooltip} from '@mui/material';


function StatusIndicator(props) {
    const [isLoading, setIsLoading] = useState(true); // Optional, if you want to show a loading spinner while the fetch is in progress

    // Function to query the URL and update the state based on the response
    const queryURL = async () => {
        fetch('https://xaxymgsxwbzso3w4fj6mmmu2da0hldkw.lambda-url.us-west-2.on.aws/')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response;
            })
            .then(response => response.json())
            .then(data => {
                props.setIsSearchOnline(data["isRunning"]); // Assume the response has an 'isRunning' boolean
                setIsLoading(false); // Set loading to false once the fetch is complete
            })
            .catch(err => {
                console.error('There was an error!', err);
            });

    };

    useEffect(() => {
        queryURL()

        const interval = setInterval(() => {
            queryURL(); // Query every 30 seconds
        }, 30000);

        return () => clearInterval(interval); // Clear interval on component unmount
    }, []); // Empty dependency array means this effect runs once on mount

    // Style for the dot, changing color based on isRunning state
    const dotStyle = {
        height: '10px',
        width: '10px',
        backgroundColor: isLoading ? 'gray' : (props.isSearchOnline ? 'green' : 'red'),
        borderRadius: '50%',
        display: 'inline-block',
    };

    return (
        <Box>
            <Tooltip
                title={isLoading ? 'Search Status Loading...' : (props.isSearchOnline ? 'Online' : 'Offline')}
                placement="top">
                <div style={dotStyle}></div>
            </Tooltip>
        </Box>
    );
}

export default StatusIndicator;
