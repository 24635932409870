import React from 'react';
import {Box, ListItem, Tooltip, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LaunchIcon from '@mui/icons-material/Launch';
import {getExpandedKeysForKey} from "../../utils/code_utils";


const CustomListItem = ({
                            hidden,
                            item,
                            index,
                            maxScore,
                            checked,
                            handleClick,
                            setExpandedKeys,
                            setSelectedCategory,
                            setHighlightedKeys
                        }) => {

    if (hidden) {
        return <></>;
    }
    const getTextColor = (_score) => {
        return 'black'

        if (checked) {
            return 'black'
        }

        // Define the minimum RGB value for light gray
        const minRGB = 200; // Light gray floor
        // Assuming _score ranges from 0 to maxScore
        // Adjust the range so that the color scales from light gray (minRGB) to black (0)
        const colorValue = minRGB - Math.round((_score / maxScore) * minRGB);
        return `rgb(${colorValue}, ${colorValue}, ${colorValue})`; // Generates an RGB color from light gray to black
    };

    const darkGreen = '#006400'; // Define dark green color

    const {Vocabulary: vocabulary, Code: code, Description: description, Key: key} = item['_source'];

    return (
        <ListItem
            key={index}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 0,
                '&:hover .changeColorOnHover': {
                    color: checked ? 'white' : darkGreen, // Change color to dark green on hover
                    fontWeight: 'bold',
                },
                backgroundColor: checked ? darkGreen : 'inherit', // Highlight background if checked
                // ml: item['_source']['Code'].length
            }}

        >
            <Box sx={{
                display: 'flex', alignItems: 'center',
                // bgcolor: "lightblue",
                flexGrow: 1, // Add this line
                overflow: 'hidden' // Add this line
            }}
                 onClick={handleClick}
            >
                <Box sx={{
                    display: 'flex', alignItems: 'center',
                }}
                >
                    {/* Adjusted Vocabulary Initials Box */}
                    <Box sx={{
                        ml: 1, // Adequate padding from the left
                        backgroundColor: 'grey',
                        color: 'white',
                        padding: '2px 6px', // Smaller padding for a tighter box
                        borderRadius: '4px',
                        fontSize: '0.65rem', // Smaller font size for the text
                        fontWeight: 'bold',
                        marginRight: '8px', // Adequate padding between this box and the code
                        minWidth: '24px', // Ensure box has a minimum width to accommodate 3 characters
                        textAlign: 'center', // Center the text within the box
                    }}>
                        {vocabulary}
                    </Box>

                    {/* Code Typography with adjusted margin for vertical alignment */}
                    <Typography
                        variant="body1"
                        className="changeColorOnHover" // Add class for hover effect
                        sx={{
                            fontWeight: 'bold',
                            color: getTextColor(item['_score']),
                            display: 'flex',
                            alignItems: 'center', // Ensures vertical alignment/justification with the box
                        }}
                    >
                        {code}
                    </Typography>
                </Box>

                {/* Description with lighter color (adjusted based on _score) and ellipsis */}
                <Box sx={{display: 'flex', alignItems: 'center', flexGrow: 1, ml: 2, overflow: 'hidden'}}>
                    <Tooltip title={description} placement="right" arrow>

                        <Typography
                            variant="body2"
                            className="changeColorOnHover" // Add class for hover effect
                            sx={{
                                color: getTextColor(item['_score']),
                                mr: 2,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {description}
                        </Typography>
                    </Tooltip>

                </Box>
            </Box>

            <Tooltip title={"Open in Context"} placement="left" arrow
                     onClick={() => {
                         const expandedKeysToOpen = getExpandedKeysForKey(key);
                         setExpandedKeys(expandedKeysToOpen);
                         setSelectedCategory(key.split("$").shift());
                         setHighlightedKeys([key]);
                     }}
            >
                <IconButton
                    size="small"
                    sx={{mr: 1, color: 'black'}}
                >
                    <LaunchIcon fontSize="inherit"/>
                </IconButton>
            </Tooltip>

        </ListItem>
    );
};

export default CustomListItem;
