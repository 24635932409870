import Typography from "@mui/material/Typography";
import React from "react";
import Box from "@mui/material/Box";
import Skeleton from '@mui/material/Skeleton';

export const Space = <Typography>&nbsp;</Typography>;
export const LoadingElement = (<Box sx={{ml: 2}}>
        <Skeleton animation="wave" width={"400px"}/>
        <Skeleton animation="wave" width={"400px"}/>
        <Skeleton animation="wave" width={"400px"}/>
        <Skeleton animation="wave" width={"400px"}/>
        <Skeleton animation="wave" width={"400px"}/>
        <Skeleton animation="wave" width={"400px"}/>
        <Skeleton animation="wave" width={"400px"}/>
        <Skeleton animation="wave" width={"400px"}/>
        <Skeleton animation="wave" width={"400px"}/>
        <Skeleton animation="wave" width={"400px"}/>
        <Skeleton animation="wave" width={"400px"}/>
        <Skeleton animation="wave" width={"400px"}/>
        <Skeleton animation="wave" width={"400px"}/>
        <Skeleton animation="wave" width={"400px"}/>
        <Skeleton animation="wave" width={"400px"}/>
        <Skeleton animation="wave" width={"400px"}/>
        <Skeleton animation="wave" width={"400px"}/>
        <Skeleton animation="wave" width={"400px"}/>
        <Skeleton animation="wave" width={"400px"}/>
    </Box>);

export const TREE_CATEGORIES = ['CPT', 'ICD9', 'ICD10', 'ATC'];

export const getTqlVocabulary = (codeSystem) => {
    const mapping = {
        "ICD-10-CM": "ICD10",
        "V-ICD9CM": "ICD9",
        "1002795": "CPT",
        "V-ATC": "ATC"
    };

    if (!mapping[codeSystem]) {
        throw new Error();
    }

    return mapping[codeSystem];
}

export const getKeyVocabulary = (tqlVocab) => {
    const reverseMapping = {
        "ICD10": "ICD-10-CM",
        "ICD9": "V-ICD9CM",
        "CPT": "1002795",
        "ATC": "V-ATC"
    };

    if (!reverseMapping[tqlVocab]) {
        throw new Error();
    }

    return reverseMapping[tqlVocab];
}
export const FLAG_SETTINGS = [
    // {key: 'simplifyTQL', label: 'Simplify TQL?', enabled: true},
    // {key: 'unionTQL', label: 'Union TQL?', enabled: false},
    {key: 'convertToICD9', label: 'Include ICD9 Conversion?', enabled: false}
    // {key: 'generateSourceRx', label: 'Generate SOURCE_RX?', enabled: false}
];