import React, {Profiler} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {store} from './redux/store'
import {Provider} from 'react-redux'
import { Authenticator } from '@aws-amplify/ui-react';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Profiler id="App">

        <Authenticator hideSignUp
        >
            {({ signOut, user }) => (
                <Provider store={store}>

                    <App/>
                </Provider>
            )}
        </Authenticator>


    </Profiler>
);

