import {Box, Checkbox, Grid, Icon, IconButton, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {handleCheck} from "../utils/util";
import Tooltip, {tooltipClasses, TooltipProps} from '@mui/material/Tooltip';
import {Component} from "react";
import {styled} from '@mui/material/styles';


function getExtractedElement(props, node, children) {
    if (props.expandedKeys.includes(node.Key) && children.length >= 0) {
        return <IconButton sx={{p: 0}} onClick={() => {
            const newExpandedKeys = props.expandedKeys.filter(key => key !== node.Key)
            props.setExpandedKeys(newExpandedKeys)
        }}>
            <ExpandMoreIcon/>
        </IconButton>
    } else if (children.length === 0) {
        return <IconButton sx={{p: 0}} disabled={true}><Icon/></IconButton>
    } else {
        return <IconButton sx={{p: 0}} onClick={() => {
            const newExpandedKeys = [...(props.expandedKeys), node.Key]
            props.setExpandedKeys(newExpandedKeys)
        }}>
            <ChevronRightIcon/>
        </IconButton>
    }
}


function getSearchHierarchyElement(isSearchHierarchy){
    if(isSearchHierarchy){
        return <IconButton sx={{p: 0}}>
            <FiberManualRecordIcon/>
        </IconButton>
    } else {
        return <IconButton sx={{p: 0}} disabled={true}><Icon/></IconButton>
    }
}


function getChildElements(children, props, node, synonyms) {
    if (children && props.expandedKeys.includes(node.Key)) {
        return (
            <Box sx={{ml: 4, mt: 0.5}}>
                <div>
                    {children.map((child, index) => (
                        <TreeNode
                            synonyms={synonyms}
                            key={index}
                            treeData={props.treeData}
                            flatData={props.flatData}
                            node={child}
                            expandedKeys={props.expandedKeys}
                            setExpandedKeys={props.setExpandedKeys}
                            showSearchTermsInContext={props.showSearchTermsInContext}
                            searchResults={props.searchResults}

                            // checkedKeys={props.checkedKeys}
                            // setCheckedKeys={props.setCheckedKeys}
                            checkedKeysWithData={props.checkedKeysWithData}
                            setCheckedKeysWithData={props.setCheckedKeysWithData}
                            highlightedKeys={props.highlightedKeys}

                            indeterminateKeys={props.indeterminateKeys}
                            setIndeterminateKeys={props.setIndeterminateKeys}

                        />
                    ))}
                </div>
            </Box>)
    } else {
        return <></>
    }
}

export class TreeNode extends Component<{}> {


    render() {
        const node = this.props.node

        const isSelected = this.props.checkedKeysWithData === undefined ?
            false :
            this.props.checkedKeysWithData.map(item => item.Key).includes(node.Key)
        const isIndeterminate = this.props.indeterminateKeys.includes(node.Key)


        const highlight = this.props.highlightedKeys.includes(node.Key)
        const isSearchResult = (() => {
            if (this.props.searchResults === null) {
                return false
           }
            const keys = this.props.searchResults.map(item => item['_source']['Key'])
            if(keys.length === 0){
                return false
            }
            if(keys.filter(key => node.Key.startsWith(key)).length > 0){
                return true
            } else{
                return false
            }
        })();

        // function to expand node and subnodes until it a search result is found
        // const expandToSearchResult = (() => {
        //     const searchKeys = this.props.searchResults.map(item => item['_source']['Key'])
        //     let newExpandedKeys = this.props.expandedKeys
        //
        //
        //     if(searchKeys.filter(key => node.Key.startsWith(key)).length > 0){
        //         return true
        //     } else{
        //         return false
        //     }
        //
        //
        //     this.props.setExpandedKeys([...this.props.expandedKeys, node.Key])
        // })();

        const searchHierarchyElement = (() => {
            const emptyEl = <IconButton sx={{p: 0}} disabled={true}><Icon/></IconButton>
            const presentEl = <IconButton sx={{p: 0}}>
                <FiberManualRecordIcon
                    sx={{color: 'lightgrey'}}
                />
            </IconButton>

            if (this.props.searchResults === null) {
                return emptyEl
            }else if(isSearchResult || isSelected || isIndeterminate){
                return emptyEl
            }

            const keys = this.props.searchResults.map(item => item['_source']['Key'])

            if(keys.filter(key => key.startsWith(node.Key)).length > 0){
                return presentEl
            }

            return emptyEl

        })();


        let synonymStr
        if (typeof node.Synonyms === "undefined") {
            synonymStr = ""
        } else {
            if (Object.keys(node['Synonyms']).length !== 0 && node['Synonyms'].length !== 1) {
                synonymStr = node['Synonyms'].join("\n")
            } else {
                synonymStr = "<no synonyms>"
            }
        }

        const children = typeof node.children === "undefined" ? [] : node.children

        if (isSelected && isIndeterminate) {
            throw Error()
        }

        let expandedEl = getExtractedElement(this.props, node, children);


        let childrenEl = getChildElements(children, this.props, node, this.props.synonyms);

        const CustomWidthTooltip = styled(({className, ...props}: TooltipProps) => (
            <Tooltip {...props} classes={{popper: className}}/>
        ))({
            [`& .${tooltipClasses.tooltip}`]: {
                maxWidth: 750,
            },
        });


        return <>
            <Grid
                wrap={"nowrap"}
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justifyContent="start"
                sx={{
                    p: 0,
                    pl: 1,
                    fontWeight: isSelected || isIndeterminate ? "500" : "300",
                    // backgroundColor: highlight ? 'yellow' : 'transparent',
                    animation: highlight ? 'highlight 3s' : 'none',
                    transition: 'opacity 3s ease-out',
                    // opacity: highlight ? '1' : '0',
                }}
            >
                {searchHierarchyElement}

                {expandedEl}
                <Checkbox key={node['Key']}
                          checked={isSelected}
                          indeterminate={isIndeterminate}
                          onChange={(e) => handleCheck(e, node.Key, this.props.checkedKeysWithData, this.props.indeterminateKeys, this.props.setCheckedKeysWithData, this.props.setIndeterminateKeys)}
                          sx={{p: 0}}
                />
                <CustomWidthTooltip
                    // title={<div style={{whiteSpace: 'pre-line'}}>{synonymStr}</div>}
                    enterDelay={750}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                width: '500'
                            },
                        },
                    }}
                    title={<>
                        <div style={{whiteSpace: 'pre-line'}}>
                            <Typography component={"h1"}
                                        color="inherit">{node['Code']}: {node['Description']}</Typography>
                            {synonymStr}
                            {/*{synonymStr.split("\n").map((line, index) => (*/}
                            {/*    <Typography key={index} color="inherit">{line}</Typography>*/}
                            {/*))}*/}
                        </div>
                    </>
                    }
                    placement="right"
                    arrow
                >
                    <Typography
                        noWrap
                        sx={{
                            p: 0,
                            pl: 1,
                            fontWeight: isSelected || isIndeterminate ? "500" : "300",
                            backgroundColor: isSearchResult ? 'lightyellow' : 'transparent',
                            color: isSearchResult && !(isSelected || isIndeterminate) ? "darkblue" : "black"
                        }}
                    >
                        {node['Code']}: {node['Description']}
                    </Typography>
                </CustomWidthTooltip>
            </Grid>
            {childrenEl}
        </>
    }
}
