import React, {useState} from 'react';
import { signOut } from 'aws-amplify/auth';


// import LeftDrawerComponent from "./components/Root/LeftDrawerComponent";
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import DeleteIcon from '@mui/icons-material/Delete';
import Popover from '@mui/material/Popover';


import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Drawer from '@mui/material/Drawer';
import 'react-toastify/dist/ReactToastify.css';
import IconButton from '@mui/material/IconButton';
import {Box, Button, Tooltip, Typography} from '@mui/material';
import {copyCodes, extractRelevantCodes} from "../../utils/code_utils";
import {copyTQL} from "../../utils/tql_utils";

export default function LeftDrawerComponent(props) {

    function SettingsComponent(props) {

        const [anchorEl, setAnchorEl] = useState(null)

        const handleClick = (e) => {
            setAnchorEl(e.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);

        return (<>
                <Button
                    variant="contained"
                    onClick={handleClick}
                    sx={{width: 'calc(50% - 4px)', mr: '8px'}}
                    color={"grey"}
                >Settings</Button>
                <Popover
                    id={1234}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Box sx={{padding: 2}}>
                        {props.flagSettings.map(({key, label, enabled}) => (

                            <FormControlLabel
                                key={key}
                                control={
                                    <Checkbox
                                        checked={props.configFlags[key]}
                                        onChange={(e) => props.updateFlag(key, e.target.checked)}
                                        sx={{

                                            padding: '0px',
                                            margin: '0px 4px 0px 0px'
                                        }}
                                        disabled={!enabled}
                                    />
                                }
                                label={<Typography component={"span"} variant="body2">{label}</Typography>}
                                sx={{
                                    display: 'block',
                                    margin: '4px 0',
                                    justifyContent: 'space-between',
                                }}
                            />

                        ))}
                    </Box>
                </Popover>
            </>
        )
    }

    if (typeof props.checkedKeysWithData === "undefined") return

    const allVocabs = [...new Set(props.checkedKeysWithData.map(item => item['Key'].split("$").shift()))]

    const codes = allVocabs.map(vocab => {
        const filteredKeysWithData = props.checkedKeysWithData.filter(item => item['Key'].split("$").shift() === vocab)
        if (filteredKeysWithData.length === 0) {
            return null
        }

        let tqlVocab = vocab

        const filteredKeys = filteredKeysWithData.map(item => item['Key'])

        const codes = extractRelevantCodes(tqlVocab, filteredKeys)

        const relevantKeysWithData = filteredKeysWithData
            .filter(item => codes.includes(item['Code']))
            .sort((a, b) => a.Key.localeCompare(b.Key))

        return [tqlVocab, relevantKeysWithData]
    }).filter(str => str)


    return (
        <Drawer
            variant="permanent"
            sx={{
                width: props.leftDrawerWidth,
                height: '100vh', // Ensure the drawer takes up the full viewport height
                flexShrink: 0, '& .MuiDrawer-paper': {
                    width: props.leftDrawerWidth,
                    boxSizing: 'border-box',
                    backgroundColor: 'grey.600',
                    color: '#fff',
                    display: 'flex',
                    flexDirection: 'column', // Use flex column layout to organize content vertically
                },
            }}
        >
            <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                {/* Title */}
                <Box>
                    <Typography variant="h4" noWrap sx={{padding: 2}}>
                        Code Navigator
                    </Typography>
                </Box>
                {/* Scrollable List */}
                <Box
                    display="flex"
                    flexDirection="column"
                    height="100vh" // Make sure the box takes up 100% of the viewport height
                    flex="1"
                    overflow="auto" // This will become scrollable if the content exceeds its height
                    sx={{
                        p: 2
                    }}
                >
                    <Typography variant="h5" noWrap sx={{padding: 2}}>
                        Selected Terms
                    </Typography>

                    <List
                        sx={{
                            bgcolor: 'grey.600', // Ensures the List matches the Drawer's background color
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: '100%',
                            '& ul': {padding: 0},
                            '& .MuiListSubheader-root': { // Targets the ListSubheader root class
                                bgcolor: 'grey.600', // Sets the background color to match the List/Dra wer
                                color: 'white', // Ensures the text color is white for contrast
                            },
                            '& .MuiListItem-root': {
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.08)', // Adjusted for visibility
                                },
                            },
                            // '& .MuiListItem-'
                        }}
                        subheader={<li/>}
                    >
                        {codes.map(codeList => codeList.sort()).map(codeList => {
                            return (
                                <li key={`section-${codeList[0]}`}>
                                    <ul>
                                        <ListSubheader>{codeList[0]}</ListSubheader>
                                        {codeList[1].map(item => {
                                            return (
                                                <ListItem sx={{
                                                    color: 'white',
                                                    p: 0,
                                                    pl: 4,
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: '100%',
                                                    boxSizing: 'border-box',
                                                }}
                                                          key={item['Key']}
                                                >
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flex: 1, // Allows the container to grow and fill available space, pushing the icon to the right
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden'
                                                    }}>
                                                        <Typography
                                                            component="span"
                                                            sx={{
                                                                fontWeight: 'bold',
                                                                marginRight: 1,
                                                            }}
                                                        >
                                                            {item['Code']}
                                                        </Typography>
                                                        <Tooltip title={item['Description']} placement="right" arrow>
                                                            <Typography
                                                                component="span"
                                                                sx={{
                                                                    color: 'grey.500',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    flex: 1, // Allows the description to grow and fill space, ensuring it doesn't push the icon out of view
                                                                    whiteSpace: 'nowrap', // Ensures text overflow is handled with ellipsis
                                                                    mr: 1, // Adjust spacing as needed
                                                                }}
                                                            >
                                                                {item['Description']}
                                                            </Typography>
                                                        </Tooltip>
                                                    </Box>
                                                    <IconButton
                                                        sx={{
                                                            color: 'white',
                                                            padding: 0,
                                                            margin: 0,
                                                            '&:hover': {color: 'red'}
                                                        }}
                                                        onClick={() => {
                                                            const newCheckedKeysWithData = props.checkedKeysWithData.filter(checkedItem => !checkedItem['Key'].startsWith(item['Key']))
                                                            props.setCheckedKeysWithData(newCheckedKeysWithData)
                                                        }}
                                                    >
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </ListItem>

                                            )
                                        })}
                                    </ul>
                                </li>
                            )
                        })}
                    </List>


                </Box>

                <Box sx={{
                    padding: 2,
                    borderTop: '1px solid rgba(255, 255, 255, 0.12)', // Add a border to separate the content
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',

                    height: 'auto' // Allow the box to grow and shrink as needed
                    // center align

                }}
                >
                    {/* First Line */}
                    <Box sx={{display: 'flex', mb: 1}}>
                        <Button
                            variant="contained"
                            onClick={() => copyTQL(props.checkedKeysWithData, props.configFlags)}
                            sx={{width: 'calc(50% - 4px)', mr: '8px'}} // Adjust for spacing
                        >Copy TQL</Button>
                        <Button
                            variant="contained"
                            onClick={() => copyCodes(codes, props.email)}
                            sx={{width: 'calc(50% - 4px)'}} // Adjust for button spacing
                        >Copy Codes</Button>
                    </Box>

                    {/* Second Line */}
                    <Box sx={{display: 'flex', mb: 1}}>
                        <SettingsComponent
                            {...props}
                        />
                        <Button
                            variant="contained"
                            color="error"
                            onClick={props.clearAll}
                            sx={{width: 'calc(50% - 4px)'}} // Consistent with first line
                        >Clear All</Button>
                    </Box>
                    <Button
                        variant="contained"
                        // color="error"
                        onClick={signOut}
                        // sx={{width: 'calc(50% - 4px)'}} // Consistent with first line
                    >Log Out</Button>
                </Box>


            </Box>
        </Drawer>
    )
}