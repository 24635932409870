import {store} from "../../redux/store";
import {Box, Button, ButtonGroup, Grid, Tooltip, Typography} from '@mui/material';
import {Space} from "../../utils/constants";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {Checkbox} from "@mui/material";




export function RxNormList() {
    // prop={el => el['Description']}
    // prop1={description => <Typography>{description}</Typography>}

    return (
        <Box>
            <Typography>Work in Progress</Typography>
            {/*{store.getState()*/}
            {/*    .main.rxnormIngredientFlatData*/}
            {/*    .map((ingredient, index) =>*/}
            {/*    <Grid container direction={"row"} key={index}*/}
            {/*          // sx={{my: 0.5, bgcolor: (index === selectedIndex ? 'primary.light' : 'primary')}}*/}
            {/*          sx={{my: 0.5}}*/}
            {/*    >*/}
            {/*        <Grid container item direction={"row"} xs={10} justifyContent={"flex-start"}*/}
            {/*              alignItems={"center"} wrap="nowrap">*/}
            {/*            <Typography>{ingredient['RxNorm']}</Typography>*/}
            {/*            {Space}*/}
            {/*            <Typography noWrap*/}
            {/*                        sx={{color: 'lightgrey'}}>{ingredient['Description']}</Typography>*/}
            {/*        </Grid>*/}
            {/*        <Grid container item direction={"row"} xs={2} justifyContent={"flex-end"}*/}
            {/*              alignItems={"center"}*/}
            {/*              wrap="nowrap">*/}
            {/*            /!*<Tooltip title={ingredient['RxNormType']}>*!/*/}
            {/*            /!*    <Typography noWrap*!/*/}
            {/*            /!*                sx={{*!/*/}
            {/*            /!*                    mr: 1,*!/*/}
            {/*            /!*                    color: highlightIfSelected(index)*!/*/}
            {/*            /!*                }}>{hit['RxNormType']}</Typography>*!/*/}
            {/*            /!*</Tooltip>*!/*/}
            {/*            <Tooltip title={"Link to RxNav"} placement="right">*/}
            {/*                <Button*/}
            {/*                    onClick={() => window.open("https://mor.nlm.nih.gov/RxNav/search?searchBy=RXCUI&searchTerm=" + ingredient['RxNorm'], "_blank", "noreferrer")}>*/}
            {/*                    <img src={"https://lhncbc.nlm.nih.gov/RxNav/assets/images/rxnav.png"}*/}
            {/*                         height={"15px"} alt={"rxnav icon"}/>*/}
            {/*                </Button>*/}
            {/*            </Tooltip>*/}
            {/*            <IconButton*/}
            {/*                sx={{*/}
            {/*                    p: 0,*/}
            {/*                }}*/}
            {/*                aria-label="copy"*/}
            {/*                // onClick={() => props.copyTQL("RxNorm", [hit['RxNorm']], props.unionTQL, props.simplifyTQL, props.generateSourceRx)}>*/}
            {/*                >*/}
            {/*                <ContentCopyIcon/>*/}
            {/*            </IconButton>*/}
            {/*            <Checkbox*/}
            {/*                sx={{m: 0, p: 0}}*/}
            {/*                // checked={props.selectedHits.map(selectedHit => selectedHit['RxNorm']).includes(hit['RxNorm'])}*/}
            {/*                // onChange={(e) => {*/}
            {/*                //     let newSelectedHits*/}
            {/*                //     if (e.target.checked) {*/}
            {/*                //         newSelectedHits = [...props.selectedHits, hit].flat(Infinity)*/}
            {/*                //     } else {*/}
            {/*                //         newSelectedHits = props.selectedHits*/}
            {/*                //             .filter(selHit => selHit['RxNorm'] !== hit['RxNorm'])*/}
            {/*                //     }*/}
            {/*                //*/}
            {/*                //     props.setSelectedHits(newSelectedHits)*/}
            {/*                // }}*/}
            {/*            />*/}

            {/*            /!*{props.selectedHits*!/*/}
            {/*            /!*    .some(selectedHit => hit['RxNorm'] === selectedHit['RxNorm']) ?*!/*/}
            {/*            /!*    // <IconButton sx={{p: 0}} disabled={true}>*!/*/}
            {/*            /!*    //     <Icon/>*!/*/}
            {/*            /!*    // </IconButton>*!/*/}
            {/*            /!*    // :*!/*/}
            {/*            /!*    // <IconButton*!/*/}
            {/*            /!*    //     sx={{*!/*/}
            {/*            /!*    //         p: 0,*!/*/}
            {/*            /!*    //         color: highlightIfSelected(index)*!/*/}
            {/*            /!*    //     }}*!/*/}
            {/*            /!*    //     aria-label="add" onClick={e => props.handleSelectedTerms(e, hit)}>*!/*/}
            {/*            /!*    //     <AddIcon/>*!/*/}
            {/*            /!*    // </IconButton>*!/*/}
            {/*            /!*}*!/*/}

            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*)}*/}
        {/*<List>*/}
        {/*{store.getState()*/}
        {/*    .main.rxnormIngredientFlatData*/}
        {/*    .map(el => el['Description'])*/}
        {/*    .map(description => <Typography>{description}</Typography>)*/}
        {/*}*/}
        {/*</List>*/}
    </Box>
)
}