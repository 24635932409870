import {CloudWatchLogsClient, PutLogEventsCommand} from "@aws-sdk/client-cloudwatch-logs";
import {extractRelevantCodes} from "./code_utils";


export default async function putDataToCloudwatch(event, keys) {
    const client = new CloudWatchLogsClient({
        region: "us-west-2",
        credentials: {
            accessKeyId: "AKIATBYAU3VE27W3DZXA",
            secretAccessKey: "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"
        }
    });


    const allVocabs = [...new Set(keys.map(key => key.split("$").shift()))]

    const relevantCodes = {}

    const tql = allVocabs.map(vocab => {
            const filteredKeys = keys.filter(key => key.split("$").shift() === vocab)
            if (filteredKeys.length === 0) {
                return null
            }


            // append to relevant keys
            const localcodes = extractRelevantCodes(vocab, filteredKeys)
            relevantCodes[vocab] = localcodes
            // relevantKeys.push(...extractRelevantCodes(vocab, filteredKeys))
        }
    );

    // console.log(relevantCodes)

    const input = { // PutLogEventsRequest
        logGroupName: "codenavigator_copied_codes", // required
        logStreamName: "main", // required
        logEvents: [ // InputLogEvents // required
            { // InputLogEvent
                timestamp: new Date().getTime(), // required
                message: JSON.stringify({
                        email: localStorage.getItem('email'),
                        env: process.env.NODE_ENV,
                        event: event,
                        codes: relevantCodes
                    }
                ), // required
            },
        ],
    };
    const command = new PutLogEventsCommand(input);
    await client.send(command);
// { // PutLogEventsResponse
//   nextSequenceToken: "STRING_VALUE",
//   rejectedLogEventsInfo: { // RejectedLogEventsInfo
//     tooNewLogEventStartIndex: Number("int"),
//     tooOldLogEventEndIndex: Number("int"),
//     expiredLogEventEndIndex: Number("int"),
//   },
// };

}