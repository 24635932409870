import {Box} from '@mui/system';
import React from 'react';
import {TreeNode} from "./TreeNode";
import {LoadingElement} from "../utils/constants";
import {useSelector} from 'react-redux';

export default function TreeVisualizationComponent(props) {
    const treeData = useSelector((state) => {
        switch (props.vocabulary) {
            case "ICD10":
                return state.main.icd10TreeData
            case "ICD9":
                return state.main.icd9TreeData
            case "CPT":
                return state.main.cptTreeData
            case "ATC":
                return state.main.atcTreeData
            default:
                throw new Error()
        }
    });

    const flatData = useSelector((state) => {
        const vocabularies = [
            {key: "ICD10", dataPath: state.main.icd10FlatData},
            {key: "ICD9", dataPath: state.main.icd9FlatData},
            {key: "CPT", dataPath: state.main.cptFlatData},
            {key: "ATC", dataPath: state.main.atcFlatData}
        ];

        const matchedVocabulary = vocabularies.find(v => v.key === props.vocabulary);
        return matchedVocabulary ? matchedVocabulary.dataPath : undefined;
    });

    return (<Box component="div" sx={{flexDirection: "column", display: 'flex', p: 2, width: 1}}>

            <div>
                {!(treeData && flatData) ? <>{LoadingElement}</> : treeData.children.map((topLevel, index) => {
                    return (

                        <Box sx={{p: 0, pt: 1}} key={index}>

                            <TreeNode
                                key={index}
                                readjustKeys={null}
                                treeData={treeData}
                                flatData={flatData}
                                node={topLevel}
                                expandedKeys={props.expandedKeys}
                                setExpandedKeys={props.setExpandedKeys}
                                highlightedKeys={props.highlightedKeys}
                                showSearchTermsInContext={props.showSearchTermsInContext}
                                searchResults={props.searchResults}
                                checkedKeysWithData={props.checkedKeysWithData}
                                setCheckedKeysWithData={props.setCheckedKeysWithData}
                                indeterminateKeys={props.indeterminateKeys}
                                setIndeterminateKeys={props.setIndeterminateKeys}
                            />
                        </Box>)
                })}
            </div>


        </Box>


    )
}
