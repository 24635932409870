import React from 'react';

import "../../App.css";
import 'react-toastify/dist/ReactToastify.css';
import {Typography} from '@mui/material';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

export function CustomTextField(props) {
    const handleClearClick = () => {
        console.log("Clearing")
        props.setSearchValue('');
        props.setNumSearchResults(null);
        props.setIsFirstPageSearch(true);
    };


    // useTraceUpdate(props);

    return (
        <TextField
            autoFocus
            fullWidth
            value={props.searchValue}
            onChange={(event) => {
                props.setIsFirstPageSearch(true);
                props.setSearchValue(event.target.value);
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {/* Displaying the number of results */}
                        {typeof props.numSearchResults === "number" &&
                            <Typography style={{marginRight: 8, color: 'lightgray'}}>
                                {props.numSearchResults} results
                            </Typography>
                        }
                        {/* Clear button */}
                        {props.searchValue &&
                            <IconButton onClick={handleClearClick}>
                                <ClearIcon/>
                            </IconButton>
                        }
                    </InputAdornment>
                ),
            }}
        />
    );
}