import React, {useEffect, useState} from 'react';
import List from '@mui/material/List';

import "../../App.css";
import Skeleton from '@mui/material/Skeleton';
import Button from "@mui/material/Button";

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import 'react-toastify/dist/ReactToastify.css';
import {Box, Typography} from '@mui/material';
import {handleCheck, handleMultipleChecks} from "../../utils/util";
import StatusIndicator from "../StatusIndicator";
import CustomListItem from "./CustomListItem";
import Switch from '@mui/material/Switch';
import {CustomTextField} from "./CustomTextField";

// Switchfunction useTraceUpdate(props) {
//     const prev = useRef(props);
//     useEffect(() => {
//         const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
//             if (prev.current[k] !== v) {
//                 ps[k] = [prev.current[k], v];
//             }
//             return ps;
//         }, {});
//         if (Object.keys(changedProps).length > 0) {
//             console.log('Changed props:', changedProps);
//         }
//         prev.current = props;
//     });
// }


// Search Component Function
export default function SearchComponent(props) {
    const [isSearching, setIsSearching] = useState(false)

    const [maxScore, setMaxScore] = useState(0)
    const [isSearchOnline, setIsSearchOnline] = useState(false)

    const [isFirstPageSearch, setIsFirstPageSearch] = useState(true)

    const [showSelectedTerms, setShowSelectedTerms] = useState(false);

    const [searchSkytale, setSearchSkytale] = useState(false)


    // Destructure Props
    const {
        searchResults,
        setSearchResults,
        searchValue,
        setSearchValue,
        numSearchResults,
        setNumSearchResults,
        vocabsToSearch,
        setVocabsToSearch,
        indeterminateKeys,
        setIndeterminateKeys,
        checkedKeysWithData,
        setCheckedKeysWithData
    } = props;

    const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedSearchValue(searchValue);
        }, 1000);

        return () => {
            clearTimeout(timerId);
        };
    }, [searchValue]);

    useEffect(() => {
        if (debouncedSearchValue) {
            // Your effect here
        }
    }, [debouncedSearchValue]);


    const handleToggle = (event) => {
        setShowSelectedTerms(event.target.checked);
    };

    // Calculates max score for the Search results
    useEffect(() => {
        setIsSearching(false)

        if (searchResults && searchResults.length > 0) {
            let max = 0
            searchResults.forEach((item) => {
                if (item['_score'] > max) {
                    max = item['_score']
                }
            })
            setMaxScore(max)
        }
    }, [searchResults]);

    const checkboxes = Object.keys(vocabsToSearch).map(key => ({
        name: key,
        value: vocabsToSearch[key],
        setter: (val) => setVocabsToSearch(prev => ({...prev, [key]: val}))
    }));

    const fetchDataElasticSearch = async (payload, signal: AbortSignal) => {
        fetch('https://zfv5wo22xeoxxg6dq7se3e6bke0jmwej.lambda-url.us-west-2.on.aws/', {
            signal,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(response => {
                setSearchResults(response['hits']['hits']);
                const numhits = response['hits']['total']['value']
                setNumSearchResults(numhits)

                if (numhits < 10000) {
                    setIsFirstPageSearch(false)
                }

                console.log(response['hits']['hits'])

            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    // console.log('Fetch aborted');
                } else {
                    console.error('Fetch error:', err);
                }
            })

    }


    // const fetchDataSkytale = async (searchTerm, signal) => {
    //     fetch('http://52.42.163.226:5000/chatrwd/', {
    //         // fetch('https://webhook.site/f7fa3ecb-dd17-4d5b-9a35-68c640668523', {
    //         signal,
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //             "in_clinical_concept": searchTerm,
    //             "in_search_embed_flg": "False"
    //         })
    //     })
    //         .then(response => response.json())
    //         .then(response => {
    //             console.log(response)
    //             // setSearchResults(response['hits']['hits']);
    //             // const numhits = response['hits']['total']['value']
    //             // setNumSearchResults(numhits)
    //             //
    //             // if (numhits < 100) {
    //             //     setIsFirstPageSearch(false)
    //             // }
    //
    //         })
    //         .catch(err => {
    //             if (err.name === 'AbortError') {
    //                 // console.log('Fetch aborted');
    //             } else {
    //                 console.error('Fetch error:', err);
    //             }
    //         })
    //
    // }
    //
    // useEffect(() => {
    //     if (searchSkytale) {
    //         fetchDataSkytale(debouncedSearchValue, new AbortController().signal)
    //     }
    // }, [searchSkytale, debouncedSearchValue])


    useEffect(() => {
        setIsSearching(true)
        const abortController = new AbortController();


        if (searchSkytale) {
            return () => abortController.abort();
        }


        const vocabularies = Object.keys(vocabsToSearch).filter(key => vocabsToSearch[key])

        if (!searchValue || searchValue === "" || vocabularies.length === 0) {
            setIsSearching(false)
            setSearchResults([])
            return
        }


        const {signal} = abortController;

        const payload = {
            "query": searchValue,
            "size": isFirstPageSearch ? 10000 : 10000,
            "from": 0,
            "Vocabulary": vocabularies
        }

        fetchDataElasticSearch(payload, signal);
        return () => {
            abortController.abort("Submitting another request");
        }
    }, [isFirstPageSearch, searchValue, vocabsToSearch])


    const SearchResultElement = () => {
        // Currently Searching
        // Returns list of 30 Skeletons
        if (isSearching) {
            return (
                <>
                    {Array.from({length: 30}).map((_, index) => (
                        <Skeleton key={index} variant="text" sx={{fontSize: '1rem'}}/>
                    ))}
                </>

            )
        }

        // No Vocabularies Selected
        const vocabularies = Object.keys(vocabsToSearch).filter(key => vocabsToSearch[key])
        if (vocabularies.length === 0) {
            return (
                <>
                    <Typography>Please Select at least one vocabulary to Search. </Typography>
                </>
            )
        }


        // No Search Value
        if (!searchValue || searchValue === "") {
            return (
                <>
                    <Typography>Enter a search term to begin.</Typography>
                </>
            )
        }

        // No Results
        if (searchResults && searchResults.length === 0) {
            return (
                <>
                    <Typography>No results found.</Typography>
                </>
            )

        }

        // Null Search Results (init)
        if (!searchResults) {
            return <></>
        }


        const checkedKeys = checkedKeysWithData.map(it => it.Key)

        // Results
        return (<>
                <List>
                    {searchResults.map((item, index) => (
                        <CustomListItem
                            hidden={checkedKeys.includes(item['_source']['Key']) && !showSelectedTerms}
                            key={index}
                            item={item}
                            maxScore={maxScore}
                            checked={checkedKeys.includes(item['_source']['Key'])}
                            handleClick={(e) => handleCheck(e, item['_source']['Key'], checkedKeysWithData, indeterminateKeys, setCheckedKeysWithData, setIndeterminateKeys)}
                            setExpandedKeys={props.setExpandedKeys}
                            setSelectedCategory={props.setSelectedCategory}
                            setHighlightedKeys={props.setHighlightedKeys}
                        />
                    ))}
                </List>
                {isFirstPageSearch &&
                    <Button onClick={() => setIsFirstPageSearch(false)}>Load More</Button>
                }

            </>
        )
    }


    const selectAll = () => {
        let newCheckedKeys = searchResults.map((item) => item['_source']['Key'])
        handleMultipleChecks(newCheckedKeys, checkedKeysWithData, indeterminateKeys, setCheckedKeysWithData, setIndeterminateKeys)
            // .forEach((key) => {
            //     handleCheck(null, key, checkedKeysWithData, indeterminateKeys, setCheckedKeysWithData, setIndeterminateKeys)
            // })
    }

    return (
        <Box sx={{width: '50%', overflow: 'auto', pr: 2, m: 3}}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between', // Adjusted for spacing
                alignItems: 'center',
                width: '100%', // Ensure the box spans the full width
                height: 'fit-content'
            }}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant="h4" sx={{mb: 0, mr: 1}}>Search</Typography>
                    <StatusIndicator
                        setIsSearchOnline={setIsSearchOnline}
                        isSearchOnline={isSearchOnline}
                    />
                </Box>
                <Box>
                    {/*<Button onClick={selectAll}*/}
                    {/*        sx={{px: 2}}>Select All</Button>*/}
                    {/*<FormControlLabel*/}
                    {/*    control={<Switch checked={searchSkytale}*/}
                    {/*                     onChange={(event) => setSearchSkytale(event.target.checked)}/>}*/}
                    {/*    label="Skytale"*/}
                    {/*    sx={{marginRight: 2}} // Adjust spacing as needed*/}
                    {/*/>*/}
                    <Button
                        variant="contained"
                        sx={{mr: 3, mb: 1 }}
                        onClick={selectAll}
                    >Select All</Button>

                    <FormControlLabel
                        control={<Switch checked={showSelectedTerms} onChange={handleToggle}/>}
                        label="Show selected terms"
                        sx={{marginRight: 0}} // Adjust spacing as needed
                    />

                </Box>
            </Box>

            <CustomTextField
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                numSearchResults={numSearchResults}
                setNumSearchResults={setNumSearchResults}
                setIsFirstPageSearch={setIsFirstPageSearch}
                onChange={(event) => {
                    setSearchValue(event.target.value)
                }}
            />

            <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                {checkboxes.map(({name, value, setter}) => (
                    <FormControlLabel
                        key={name}
                        control={<Checkbox checked={value} onChange={() => setter(!value)}/>}
                        label={name}
                    />
                ))}
            </Box>
            {/*Render the Search results if isSearching is false*/}

            <SearchResultElement
                isFirstPageSearch={isFirstPageSearch}
                showSelectedTerms={showSelectedTerms}
                searchResults={searchResults}
                numSearchResults={numSearchResults}
                setSearchResults={setSearchResults}
                setNumSearchResults={setNumSearchResults}
            />
            {/*Additional Search-related content */}
        </Box>
    )
}